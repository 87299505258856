@use 'sass:math';
@import '_vars';

@import "fontawesome/fontawesome";
@import "fontawesome/brands";
@import "fontawesome/solid";
@import "fontawesome/regular";

@font-face {
    font-family: "AmsiProCond";
    src: url("#{$fonts}AmsiProCond-Black.woff2") format("woff2"),
        url("#{$fonts}AmsiProCond-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

// dev 2

// dev 3

// dev 4

// dev 5
